
import common from '@/store/modules/swat/screens/common/common.js';

const state = () => ({
  ...common.state(),
  jsdo: [],
  cursor: ''
});

const mutations = {
  ...common.mutations,
  PARSE(state, records) {
    state.jsdo = records;
  },
  ADD(state, record) {
    state.jsdo.push(record);
  },
  DELETE(state, id) {
    const record = state.jsdo.find(record => record._id === id);
    const index = state.jsdo.indexOf(record);
    if (index > -1)
      state.jsdo.splice(index, 1);

  },
  SET_CURSOR(state, cursor) {
    state.cursor = cursor;
  }
};

const actions = { ...common.actions };

const getters = {
  ...common.getters,
  getCurrentRecord: state => () => {
    const currentRecord = state.jsdo.find(record => record._id === state.cursor);
    const index = state.jsdo.indexOf(currentRecord);

    if (index > -1)
      return state.jsdo[index];

    return null;
  },
  getCursor: state => state.cursor

};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
