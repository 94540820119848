import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
const state = () => ({
  layout: { settings: [] },
  grid: { settings: [] },
  form: { settings: [] },
  theme: {
    key: '',
    selfhdl: ''
  }
});


const mutations = {

  REMOVE_LAYOUT_SETTING(state, { id }) {
    const item = _find(state.layout.settings, { id });
    state.layout.settings.splice(state.settings.indexOf(item), 1);
  },
  ADD_LAYOUT_SETTING(state, obj) {
    state.layout.settings.push(obj);
  },
  UPDATE_LAYOUT_SETTING(state, { id, newSettings }) {
    const index = _findIndex(state.layout.settings, { id });
    state.layout.settings.splice(index, 1, newSettings);

    return index;
  },
  ADD_GRID_SETTING(state, obj) {
    state.grid.settings.push(obj);
  },
  UPDATE_GRID_SETTING(state, { id, newSettings }) {
    const index = _findIndex(state.grid.settings, { id });
    state.grid.settings.splice(index, 1, newSettings);

    return index;
  },
  ADD_FORM_SETTING(state, obj) {
    state.form.settings.push(obj);
  },
  UPDATE_FORM_SETTING(state, { id, newSettings }) {
    const index = _findIndex(state.form.settings, { id });
    state.form.settings.splice(index, 1, newSettings);

    return index;
  },
  LOAD_SETTINGS(state, settings) {
    try {
      state.layout.settings = settings.layout.settings;
      state.grid.settings = settings.grid.settings;
      state.form.settings = settings.form.settings;
    } catch (e) {
      console.warn('Error loading user profile settings in vuex store.');
    }

  },
  LOAD_LAYOUT_SETTINGS(state, settings) {
    state.layout.settings = settings;
  },
  LOAD_GRID_SETTINGS(state, settings) {
    state.grid.settings = settings;
  },
  LOAD_FORM_SETTINGS(state, settings) {
    state.form.settings = settings;
  },
  SET_USER_ACTIVE_THEME(state, theme) {
    state.theme = theme;
  }
};

const actions = {
  setLayoutObjectSettings({ state, commit }, objSettings) {
    const index = _findIndex(state.layout.settings, { id: objSettings.id });
    if (index > -1)
      commit('UPDATE_LAYOUT_SETTING', { id: objSettings.id, newSettings: objSettings });
    else
      commit('ADD_LAYOUT_SETTING', objSettings);


    localStorage.setItem('ak_userprofile_v2', JSON.stringify(state));
  },
  setGridObjectSettings({ state, commit }, objSettings) {
    const index = _findIndex(state.grid.settings, { id: objSettings.id });
    if (index > -1)
      commit('UPDATE_GRID_SETTING', { id: objSettings.id, newSettings: objSettings });
    else
      commit('ADD_GRID_SETTING', objSettings);


    localStorage.setItem('ak_userprofile_v2', JSON.stringify(state));
  },
  setFormObjectSettings({ state, commit }, objSettings) {


    const index = _findIndex(state.form.settings, { id: objSettings.id });


    if (index > -1)
      commit('UPDATE_FORM_SETTING', { id: objSettings.id, newSettings: objSettings });
    else
      commit('ADD_FORM_SETTING', objSettings);


    localStorage.setItem('ak_userprofile_v2', JSON.stringify(state));
  }
};

const getters = {
  getUserProfileLayoutSettings: state => () => state.layout.settings,
  getLayoutObjectSetting: state => id => {
    const item = _find(state.layout.settings, { id });
    return item;
  },
  getGridProfileLayoutSettings: state => () => state.grid.settings,
  getGridObjectSetting: state => id => {
    const item = _find(state.grid.settings, { id });
    return item;
  },
  getFormObjectSetting: state => id => {
    const item = _find(state.form.settings, { id });
    return item;
  },
  getUserActiveTheme(state) {
    return state.theme;
  }
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
