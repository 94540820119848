import { createStore } from 'vuex';
import swat from './modules/swat';
import taskbar from './modules/taskbar';

export default createStore({
  modules: {
    swat,
    taskbar
  },
  strict: false // don't enable, bad performance
});
